import React from "react"
import PropTypes from "prop-types"
import styles from "src/styles/pages/imprint.module.scss"
import sectionStyles from "src/styles/components/section.module.scss"
import Navbar from "src/components/navbar"
import cx from "classnames"
import { graphql } from "gatsby"
import Layout from "src/components/layout"

export default function Imprint(props) {
  const {
    data: {
      allMarkdownRemark: { nodes },
    },
  } = props
  return (
    <Layout>
      <Navbar />
      <section
        className={cx(sectionStyles.root, styles.root)}
        dangerouslySetInnerHTML={{
          __html: nodes[0].html,
        }}
      />
    </Layout>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/imprint/" } }) {
      nodes {
        id
        html
      }
    }
  }
`
Imprint.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          html: PropTypes.string,
        })
      ),
    }),
  }),
}
